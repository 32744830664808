import { GLOBAL } from 'saddlebag-browser';
import logger from 'saddlebag-logger';
import observer from 'saddlebag-observer';

import { init, onPageShown } from '../../core/src/modern/index';

import setupCrossDomainLinking from './cross-domain-linking';
import {
  sendFunnelViewEvent,
  sendFunnelAcquisitionEvent,
  sendExperimentationAllocationEvent,
  sendExperimentUserContextEvent,
  sendUserPreferencesEvent,
} from './funnelEvents';
import { getCookieValue } from './gaTrack';
import getTrackIdentifier from './getTrackIdentifier';
import getUserInfo from './getUserInfo';
import { initSync as gaSync, initAsync as gaAsync } from './google-analytics';
import sendViewEvent from './grappler';
import { initAsync as grapplerAsync } from './grappler/grappler';
import setPreferences from './setPreferences';
import initTrackingEventHandlers from './trackingEvent';

const doc = GLOBAL.getDocument();
const trackingScript = doc.getElementById('trackolding-sync');
const data = JSON.parse(trackingScript.getAttribute('data-data'));

const bootstrapTracking = () => {
  const gaId = getCookieValue();
  const isNewUser = gaId === null;

  setPreferences();
  return isNewUser;
};

const isIE = () => {
  if (
    typeof window.navigator !== 'undefined' &&
    typeof window.navigator.userAgent !== 'undefined'
  ) {
    return !!window.navigator.userAgent.match(/MSIE|Trident/);
  }
  return false;
};

try {
  init({
    environment: 'public',
    behavioural: {
      enabled: Boolean(
        data && data.config && data.config.emitBehaviouralEvents,
      ),
    },
  });
} catch (error) {
  logger.logError(error);
}

try {
  const $window = GLOBAL.getWindow();
  if (!$window.__trackolding) $window.__trackolding = {};
  if (!$window.__trackolding.getTrackIdentifier)
    $window.__trackolding.getTrackIdentifier = getTrackIdentifier;
  $window.__trackolding.deploy = {
    colour: data.deployColour || 'unknown',
    region: data.deployRegion || 'unknown',
  };

  const shouldInitGaEarly = isIE();

  let isNewUser;
  if (shouldInitGaEarly) {
    isNewUser = bootstrapTracking();
    gaSync(data); // must run before client-tracking-async to avoid premature ga() calls in IE11
    initTrackingEventHandlers();
  }

  getUserInfo(data, $window)
    .then((userInfo) => {
      data.userInfo = { ...data.userInfo, ...userInfo };

      if (!shouldInitGaEarly) {
        isNewUser = bootstrapTracking(data);
      }

      sendViewEvent(Object.assign(data, { isNewUser })).then(() => {
        if ($window?.__internal?.screenViewEventGuid) {
          onPageShown($window.__internal.screenViewEventGuid);
        }
      });
      sendFunnelViewEvent(data);
      sendFunnelAcquisitionEvent(data);
      sendExperimentationAllocationEvent(data);
      sendExperimentUserContextEvent(data);
      sendUserPreferencesEvent(data);
      // resending the funnel events once user interacted with cookie-banner or user cookie preferences component in legal-pages
      observer.subscribe('tracking-preferences-changed', () =>
        sendUserPreferencesEvent(data),
      );
      observer.subscribe('user-cookie-preferences-saved', () =>
        sendUserPreferencesEvent(data),
      );

      if (!shouldInitGaEarly) {
        gaSync(data);
        initTrackingEventHandlers();
      }

      observer.publish('trackolding-loaded');

      gaAsync(data);
      grapplerAsync(data);
      setupCrossDomainLinking(data.gaLinkedDomains);
    })
    .catch((err) => {
      logger.logError({
        message: `Error calling getUserInfo() sync ${err}`,
        level: 'error',
        pageName: data.microsite.name,
        component: 'trackolding',
      });
    });
} catch (err) {
  logger.logError({
    message: `Failed to initialise trackolding sync ${err}`,
    level: 'error',
    pageName: data.microsite.name,
    component: 'trackolding',
  });
}
