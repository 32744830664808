import { SingleValueCookie } from 'saddlebag-cookie';
import observer from 'saddlebag-observer';

const REQUESTASHX_COMPLETE = 'requestashx-complete';

const getPreferenceString = () => {
  const utid = new SingleValueCookie('traveller_context').getValue();
  return utid.replace(/-/g, '');
};

export default () => {
  try {
    const preferenceCookie = new SingleValueCookie('preferences');
    if (!preferenceCookie.getValue()) {
      preferenceCookie.setValue(getPreferenceString());
    }
    preferenceCookie.store(730);
    // Simulate request.ashx completing for backwards compatibility
    observer.publish(REQUESTASHX_COMPLETE, { success: true });
  } catch (err) {
    observer.publish(REQUESTASHX_COMPLETE, { success: false, err });
  }
};
