import observer from 'saddlebag-observer';

const trackers = {
  ga: (x) => observer.publish('ga-handle-analytics-event', x),
  mixpanel: function noop() {
    /* Mixpanel has been removed. This exists for backwards compatability. */
  },
};

const initTrackingEventHandlers = () => {
  const singleTrack = (trackerId, eventsData) => {
    eventsData.forEach((eventData) => trackers[trackerId](eventData));
  };
  const multiTrack = (trackersData) => {
    Object.keys(trackersData).forEach((trackerId) =>
      singleTrack(trackerId, trackersData[trackerId]),
    );
  };
  observer.subscribe('tracking-event', (x) => multiTrack(x));
};

export default initTrackingEventHandlers;
