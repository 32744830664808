/* eslint-disable */
import { GLOBAL } from 'saddlebag-browser';

const initGaObject = () => {
  const $window = GLOBAL.getWindow();
  $window.GoogleAnalyticsObject = 'ga';
  // prettier-ignore
  $window.ga = $window.ga || ((...args) => { 
      ($window.ga.q = $window.ga.q || []).push(args);
  }), $window.ga.l = 1 * new Date();
};

export default initGaObject;
