import { logOperationalEvent } from 'saddlebag-logger';

import { MiniEventsTrackingSingleton } from './MiniEventsTrackingSingleton';
import { Exit } from './events/Exit';
import { setPageGuid } from './pageGuid';

import type { EventConfiguration } from './configuration';
import type { GUID } from './pageGuid';

// eslint-disable-next-line import/prefer-default-export
export class BehaviouralSDK {
  static configuration: { enabled: boolean } = {
    enabled: false,
  };

  static init(configuration: EventConfiguration) {
    this.configuration = configuration.behavioural;
    logOperationalEvent({
      eventName: 'BehaviouralSDK_init',
      enabled: this.configuration.enabled.toString(),
    });

    if (this.configuration.enabled) {
      MiniEventsTrackingSingleton.init(configuration);
      Exit.init();
    }
  }

  static onPageShown(pageViewGUID: GUID) {
    if (this.configuration.enabled) {
      Exit.onPageShown();

      setPageGuid(pageViewGUID);
    }
  }
}
