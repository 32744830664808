import { BehaviouralSDK } from './BehaviouralSDK';

import type { EventConfiguration } from './configuration';
import type { GUID } from './pageGuid';

export const onPageShown = (initialPageViewGUID: GUID) => {
  // Send events only on client
  if (typeof window !== 'undefined') {
    // onPageShown called added to the end of the event loop.
    // This is to allow any DOM changes to occur before the page is queried.
    setTimeout(() => {
      BehaviouralSDK.onPageShown(initialPageViewGUID);
    }, 0);
  }
};

export const init = (configuration: EventConfiguration) => {
  if (typeof window !== 'undefined') {
    BehaviouralSDK.init(configuration);
  }
};
