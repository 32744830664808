import logger from 'saddlebag-logger';

import race from './race';

const USER_INFO_TIMEOUT = 500;
let wait;

const timeout = (timeoutInMillis, data) =>
  new Promise((resolve) => {
    wait = setTimeout(() => {
      logger.logError({
        message: `Call to $window.__trackolding.userInfoCallback() timed out after ${timeoutInMillis}`,
        level: 'error',
        pageName: data.microsite.name,
        component: 'trackolding',
      });
      resolve(data.userInfo);
    }, timeoutInMillis);
  });

export default function getUserInfo(data, $window) {
  const isUserInfoMissing = !((data || {}).userInfo || {}).utid;
  const isUserInfoCallbackDefined =
    typeof $window.__trackolding.userInfoCallback === 'function';
  if (isUserInfoCallbackDefined) {
    return race([
      timeout(USER_INFO_TIMEOUT, data),
      $window.__trackolding.userInfoCallback(),
    ])
      .then((response) => {
        clearTimeout(wait);
        return response;
      })
      .catch((error) => {
        clearTimeout(wait);
        logger.logError({
          message: `Call to $window.__trackolding.userInfoCallback() errored ${error.message}`,
          level: 'error',
          pageName: data.microsite.name,
          component: 'trackolding',
        });
        return data.userInfo;
      });
  }
  if (!isUserInfoCallbackDefined && isUserInfoMissing) {
    logger.logError({
      message:
        'data.userInfo or data.userInfo.utid not available and $window.__trackolding.userInfoCallback has not been set',
      level: 'error',
      pageName: data.microsite.name,
      component: 'trackolding',
    });
  }
  return Promise.resolve(data.userInfo);
}
