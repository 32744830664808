import { GLOBAL } from 'saddlebag-browser';
import { logError } from 'saddlebag-logger';
import observer from 'saddlebag-observer';

import redactUrl from '../redactUrl';

const OPT_NON_INTERACTION_UNIVERSAL = 1;
const trackedEvents = [];
let initializedListeners = false;

const generatePageNameForEvents = (uaPageView, allowedQueryParams = null) => {
  const { location } = GLOBAL.getDocument();
  const gaPath = location.pathname;
  const gaSearch = redactUrl(location.search, { allowedQueryParams });
  const gaParamKey = gaSearch.indexOf('?') > -1 ? '&' : '?';
  const gaPageName = `${gaPath}${gaSearch}${gaParamKey}gaPage=[${uaPageView}]`;
  return gaPageName;
};

const setDimension = (name, value) => {
  const { ga } = GLOBAL.getWindow();
  if (!ga || !name) return;

  ga('set', name, value);
};

const setMultipleDimensions = (dimensions) => {
  if (!dimensions) return;
  Object.keys(dimensions).forEach((dimension) => {
    setDimension(`dimension${dimension}`, dimensions[dimension]);
  });
};

const trackEvent = (
  category,
  action,
  label,
  value,
  newLocation,
  dimensions,
  uaPageView,
  allowedQueryParams = null,
) => {
  const { ga } = GLOBAL.getWindow();
  const doc = GLOBAL.getDocument();

  try {
    const gaPageName = generatePageNameForEvents(
      uaPageView || 'unknown',
      allowedQueryParams,
    );
    const eventValue = value === '' ? null : value;
    setMultipleDimensions(dimensions);
    ga('send', 'event', category, action, label, {
      page: gaPageName,
      eventValue,
      nonInteraction: OPT_NON_INTERACTION_UNIVERSAL,
    });
  } catch (e) {
    logError({
      message: (e.get_Message && e.get_Message()) || e.message || e,
      component: 'Tracking.trackEvent',
      level: 'error',
    });
  }

  if (newLocation) {
    setTimeout(() => {
      doc.location = newLocation;
    }, 100);
  }
};

const trackEventOnce = (
  category,
  action,
  label,
  value,
  newLocation,
  dimensions,
  uaPageView,
  allowedQueryParams = null,
) => {
  const key = category + action + label + value;
  if (trackedEvents.indexOf(key) < 0) {
    trackedEvents.push(key);
    trackEvent(
      category,
      action,
      label,
      value,
      newLocation,
      dimensions,
      uaPageView,
      allowedQueryParams,
    );
  }
};

const handleAnalyticsEvents = (
  event,
  uaPageView,
  allowedQueryParams = null,
) => {
  if (event === null) {
    return;
  }
  const fn = (...args) =>
    event.trackOnce ? trackEventOnce(...args) : trackEvent(...args);
  fn(
    event.category,
    event.action,
    event.label,
    event.value,
    null,
    event.dimensions,
    uaPageView,
    allowedQueryParams,
  );
};

const initGaListeners = (
  uaPageView,
  analyticsPageName,
  allowedQueryParams = null,
) => {
  if (initializedListeners) return;
  initializedListeners = true;

  observer.subscribe('ga-handle-analytics-event', (data) => {
    handleAnalyticsEvents(data, uaPageView, allowedQueryParams);
  });

  observer.subscribe('ga-track-event-with-page-name', (data) => {
    if (data) {
      trackEvent(
        analyticsPageName || 'unknown',
        data.action,
        data.label,
        data.value,
        null,
        null,
        uaPageView,
        allowedQueryParams,
      );
    }
  });
};

export {
  trackEvent,
  trackEventOnce,
  handleAnalyticsEvents,
  setDimension,
  setMultipleDimensions,
  generatePageNameForEvents,
  initGaListeners,
};
