import { MiniEventsTracking } from 'saddlebag-user-tracking-events';

import type { EventConfiguration } from './configuration';
/**
 * This is a Singleton class for initalising an instance of MiniEventsTracking and accessing it.
 */
// eslint-disable-next-line import/prefer-default-export
export class MiniEventsTrackingSingleton {
  private static instance: MiniEventsTracking;

  /**
   * The constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  // eslint-disable-next-line no-empty-function
  private constructor() {}

  public static init(configuration: EventConfiguration): void {
    MiniEventsTrackingSingleton.instance = new MiniEventsTracking(
      configuration.environment,
      'web_mini',
    );
  }

  public static getInstance(): MiniEventsTracking {
    if (!MiniEventsTrackingSingleton.instance) {
      throw new Error('MiniEventsTrackingSingleton not initialised!');
    }

    return MiniEventsTrackingSingleton.instance;
  }
}
