import { GLOBAL } from 'saddlebag-browser';
import { SingleValueCookie } from 'saddlebag-cookie';
import { addQueryStringParam } from 'saddlebag-url';

const REDIRECT_COOKIES = [
  'tck',
  'acq',
  'settings',
  'preferences',
  'auth_traveller_context',
  'traveller_context',
];

const getCookieValues = () =>
  REDIRECT_COOKIES.map((cookie) => ({
    cookie,
    value: new SingleValueCookie(cookie).getValue(),
  }));

const isRelativeUrl = (url) => url.indexOf('//') === -1;

const isSameDomain = (url) => {
  const { location } = GLOBAL.getWindow();
  return location.hostname && url.indexOf(location.hostname) > -1;
};

const isLink = (elem) =>
  elem && elem.tagName ? elem.tagName.toLowerCase() === 'a' : false;

const queryParamAddedToLink = (elem, param) => elem.search.indexOf(param) > -1;

const setupCrossDomainLinking = (skyscannerDomains) => {
  GLOBAL.getDocument().addEventListener('mousedown', (event) => {
    const element = event.target;
    if (!isLink(element)) {
      return;
    }
    const { href } = element;
    if (isRelativeUrl(href) || isSameDomain(href)) {
      return;
    }
    for (let i = 0; i < skyscannerDomains.length; i += 1) {
      const linkedDomain = skyscannerDomains[i];
      if (href.indexOf(linkedDomain) > -1) {
        getCookieValues().forEach(({ cookie, value }) => {
          if (value && !queryParamAddedToLink(element, cookie)) {
            element.href = addQueryStringParam(element.href, cookie, value);
          }
        });
        break;
      }
    }
  });
};

export default setupCrossDomainLinking;
