import { getRequestId } from 'saddlebag-browser';
import { SingleValueCookie } from 'saddlebag-cookie';
import {
  isLoggedIn,
  TRAVELLER_AUTHENTICATION_STATE,
} from 'saddlebag-user-management';
import { ViewSender } from 'saddlebag-view-tracking';

import getViewName from './getViewName';
import redactUrl from './redactUrl';

const getUserAuthState = async () =>
  (await isLoggedIn())
    ? TRAVELLER_AUTHENTICATION_STATE.AUTHENTICATED
    : TRAVELLER_AUTHENTICATION_STATE.UNAUTHENTICATED;

const sendViewEvent = async (data) => {
  const componentContext = {
    tld: data.culture.tld,
    browserName: data.device.browserName,
    browserVersion: data.device.browserVersion,
    desktop: data.device.isBrowser,
    mobile: data.device.isMobilePhone,
    tablet: data.device.isTablet,
    osVersion: data.device.osVersion,
    internalUser: data.userInfo.isInternalUser,
  };
  Object.assign(componentContext, data.culture);
  Object.assign(componentContext, data.flightSearch);
  const { isNewUser } = data.userInfo;

  const config = Object.assign(data.config, {
    featureTests: data.featureTests,
  });
  const userAuthState = await getUserAuthState();
  const { allowedQueryParams } = data.pageContext;
  return new ViewSender().sendView(
    {
      opts: {
        Api: {
          componentContext: () => componentContext,
        },
        Common: {
          getTrackId: () => data.userInfo.trackId,
        },
        config,
        redactUrl: (currentUrl) =>
          redactUrl(currentUrl, { allowedQueryParams }),
        ...data.pageContext,
        ...data.culture,
        isNewUser,
        requestId: data.requestId || getRequestId(),
      },
      hotelsOpts: data.hotels || {},
      carscannerOpts: data.carhire || {},
      trackingOpts: {
        WebNode: data.microsite.name,
        guid: data.viewGuid,
      },
      config,
    },
    () => data.userInfo.utid,
    () => new SingleValueCookie('preferences').getValue(),
    (contrailName) => getViewName(contrailName),
    data.userInfo.authState || userAuthState,
    componentContext,
  );
};

export default sendViewEvent;
