import { GrapplerLogger } from 'saddlebag-grappler';
import { logError } from 'saddlebag-logger';
import observer from 'saddlebag-observer';
import { isNonEssentialTrackingEnabled } from 'saddlebag-tracking-preferences';
import { MiniEventsTracking } from 'saddlebag-user-tracking-events';

let grapplerTracker;
let miniEventTracker;
let initializedListeners = false;

const getMiniEventTracker = (environment, componentName) => {
  if (miniEventTracker) return miniEventTracker;

  const env = environment || 'public';
  miniEventTracker = new MiniEventsTracking(env, componentName);
  return miniEventTracker;
};

const getGrapplerTracker = (environment, componentName) => {
  if (grapplerTracker) return grapplerTracker;

  const env = environment || 'public';
  grapplerTracker = new GrapplerLogger(env, componentName, { enhance: false });
  return grapplerTracker;
};

const sendGrapplerEvent = async (environment, componentName, data) => {
  try {
    if (data.isMiniEvent === true) {
      return getMiniEventTracker(environment, componentName).track(
        data.eventName,
        data.fullSchemaName,
        data.message,
        data.messageOpts,
      );
    }
    return getGrapplerTracker(environment, componentName).sendCustomEvent(
      data.eventName,
      data.fullSchemaName,
      data.message,
    );
  } catch (err) {
    logError({
      message: (err.get_Message && err.get_Message()) || err.message || err,
      component: 'grapplerEvent',
      pageName: `${componentName}`,
      eventType: `${data.eventName}`,
      level: 'error',
    });
    return {};
  }
};

/**
 * @param {string} environment environment, public or public-sandbox
 * @param {string} componentName component name
 * @param {string} market Market of operation
 * @param {object} data Object containing the event to be sent to grappler (message),
 * type of the event (isMiniEvent) and if consent is required (requiredConsent)
 * @returns {*} Returns nothing
 */
const trackGrapplerEvent = async (environment, componentName, market, data) => {
  if (data && data.eventName) {
    if (data.consentRequired !== false) {
      if (!isNonEssentialTrackingEnabled(market)) {
        return {};
      }
    }
    return sendGrapplerEvent(environment, componentName, data);
  }
  logError({
    message: `Failed to track event as eventName not provided, market: ${JSON.stringify(
      market,
    )}, data: ${JSON.stringify(data)}`,
    component: componentName || 'trackolding',
  });
  return {};
};

/**
 * @returns {*} Returns nothing
 * @param  {string} environment environment, public or public-sandbox
 * @param  {string} componentName component name
 * @param {string} market Market of operation
 */
const initGrapplerListeners = (environment, componentName, market) => {
  if (initializedListeners) return;
  initializedListeners = true;
  observer.subscribe('grappler-track', (data) => {
    if (componentName && data && market && data.isMiniEvent != null) {
      return trackGrapplerEvent(environment, componentName, market, data);
    }
    logError({
      message: 'Failed to track grappler events. Invalid input parameters.',
      component: componentName || 'trackolding',
    });
    return {};
  });
};

export { initGrapplerListeners, sendGrapplerEvent, trackGrapplerEvent };
