import { GLOBAL } from 'saddlebag-browser';
import { SingleValueCookie } from 'saddlebag-cookie';
import {
  getPreferences,
  PREFERENCES,
  isGdprInitialised,
} from 'saddlebag-tracking-preferences';

import { gaBootstrap, initGaObject, initGaPageLoadedTracking } from './gaTrack';

const initSync = () => initGaObject();

const initAsync = (data) =>
  gaBootstrap(() => {
    const doc = GLOBAL.getDocument();

    const trackingOptions = {
      CurrentUserLanguage: data.culture.locale.split('-')[0],
      userLocale: data.culture.locale,
      userCurrency: data.culture.currency,
      userGeoMarket: data.geoMarketCode || '',
      preferencesId: new SingleValueCookie('preferences').getValue(),
      visibilityState: doc.visibilityState || 'N/A',
      ShouldLogGaFeatureSetInDimension: true,
      GaSkyscannerDomains: data.gaLinkedDomains,
      userId: data.userInfo.utid,
      featureTestString: data.featureTestsString,
      config: data.config,
      WebNode: data.microsite.name,
      pageType: data.pageType || '',
      allowedQueryParams: data.pageContext.allowedQueryParams,
    };

    const trackingPreferences = getPreferences(data.culture.market);
    const gdprProps = {
      'Gdpr Information': trackingPreferences[PREFERENCES.INFORMATION],
      'Gdpr Adverts': trackingPreferences[PREFERENCES.ADVERTS],
      'Gdpr Version': trackingPreferences[PREFERENCES.VERSION],
      'Gdpr Active': trackingPreferences.isGdprActiveInMarket,
      'Gdpr Initialised': isGdprInitialised(),
    };
    Object.assign(trackingOptions, gdprProps);

    const componentContext = {
      market: data.culture.market,
      cabinClass: data.flightSearch.cabinClass,
      inboundDate: data.flightSearch.inboundDate,
    };
    const { pageSpecificGaDimensions } = data.pageContext;
    const { ancillary } = data.pageContext;
    const { analyticsPageName } = data.pageContext;
    const { uaPageView } = data.pageContext;

    initGaPageLoadedTracking(
      trackingOptions,
      componentContext,
      pageSpecificGaDimensions,
      ancillary,
      analyticsPageName,
      uaPageView,
    );
  });

export { initSync, initAsync };
export default { initSync, initAsync };
