import { SingleValueCookie } from 'saddlebag-cookie';

import gaBootstrap from './gaBootstrap';
import initGaObject from './gaInitGaObject';
import { initGaListeners } from './gaInitListeners';
import { initGaPageLoadedTracking } from './gaTrackingPageLoaded';

const getCookieValue = () => new SingleValueCookie('_ga').getValue();

export {
  getCookieValue,
  gaBootstrap,
  initGaObject,
  initGaListeners,
  initGaPageLoadedTracking,
};
