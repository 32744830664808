import debounce from 'lodash.debounce';

import { MiniEventsTrackingSingleton } from '../MiniEventsTrackingSingleton';
import { getPageGuid } from '../pageGuid';

import type { MBDEvent } from './Event';
import type { GUID } from '../pageGuid';

export const exit = ({ currentPageGuid }: { currentPageGuid?: GUID }) => {
  const tracker = MiniEventsTrackingSingleton.getInstance();
  tracker.track('exit', 'exit.Exit', {
    view_guid: currentPageGuid,
  });
};

// Callback for visibilitychange eventListener
const visibilitychange = (_event: Event) => {
  if (document.visibilityState === 'hidden') {
    const currentPageGuid = getPageGuid();
    exit({ currentPageGuid });
  }
};

export const Exit: MBDEvent = {
  init: () => {
    /**
     * If more than one visibilitychange event fires within a second, only a single call to visibilitychange fn will be made.
     * This functionality is primarily added to limit event sending during heavy tab switching behaviour
     */
    const VISIBILITY_CHANGE_DEBOUNCE_WAIT_MS = 500;
    document.addEventListener(
      'visibilitychange',
      debounce(visibilitychange, VISIBILITY_CHANGE_DEBOUNCE_WAIT_MS, {
        leading: true,
      }),
      {
        capture: true,
      },
    );
  },
  onPageShown: () => {
    // Expected to be called _before_ page guid has been updated.
    const currentPageGuid = getPageGuid();
    // If currentPageGuid is undefined, it is the first onPageShown & don't send an exit event
    if (currentPageGuid) {
      exit({ currentPageGuid });
    }
  },
};
