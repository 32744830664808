/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
import {
  GLOBAL,
  getViewportSize,
  getCommonProperties,
} from 'saddlebag-browser';
import { SingleValueCookie } from 'saddlebag-cookie';

import redactUrl from '../redactUrl';

import { initGaListeners } from './gaInitListeners';

const getGaFeatureTestString = (config) => {
  const pattern = /^.*_V\d+$/i;
  const { featureTests } = config;

  let featureTestString;
  if (config && config.featureTests) {
    featureTestString = Object.keys(featureTests)
      .reduce((acc, objectKey) => {
        if (objectKey == null) return acc;

        if (objectKey.match(pattern)) {
          acc.push(`${objectKey}-${featureTests[objectKey]}`);
        }

        return acc;
      }, [])
      .join('&&');
  }
  return featureTestString;
};

const setDimension = (dimension, value, trackerPrefix) => {
  const { ga } = GLOBAL.getWindow();
  const instructionString = `${trackerPrefix}set`;
  const dimensionString = `dimension${dimension}`;
  ga(instructionString, dimensionString, value);

  return (instructionString + dimensionString + value).length;
};

const trackPageView = (gaPageView, trackerPrefix, allowedQueryParams) => {
  const { ga } = GLOBAL.getWindow();
  const { location } = GLOBAL.getDocument();
  const gaPath = (location && location.pathname) || '';
  const gaSearch =
    (location && redactUrl(location.search, { allowedQueryParams })) || '';
  const gaParamKey = gaSearch.indexOf('?') > -1 ? '&' : '?';
  const gaPageName = `${gaPath}${gaSearch}${gaParamKey}gaPage=[${gaPageView}]`;

  const sstrackid = new SingleValueCookie('sstrack').getValue();

  if (ga) {
    if (sstrackid !== null) {
      ga(`${trackerPrefix}set`, 'userId', sstrackid);
    }
    ga(`${trackerPrefix}send`, 'pageview', gaPageName);
  }
};

const gaMultiDestProperties = (componentContext, now, analyticsPageName) => {
  const dimensionsMap = {
    'Trip Type': '56',
    'Origin Code Leg 1': '52',
    'Destination Code Leg 1': '53',
    'Date Leg 1': '54',
    'Origin Code Leg 2': '73',
    'Destination Code Leg 2': '74',
    'Date Leg 2': '75',
    'Cabin Class Leg 2': '76',
    'Origin Code Leg 3': '77',
    'Destination Code Leg 3': '78',
    'Date Leg 3': '79',
    'Cabin Class Leg 3': '80',
    'Origin Code Leg 4': '81',
    'Destination Code Leg 4': '82',
    'Date Leg 4': '83',
    'Cabin Class Leg 4': '84',
    'Origin Code Leg 5': '85',
    'Destination Code Leg 5': '86',
    'Date Leg 5': '87',
    'Cabin Class Leg 5': '88',
    'Origin Code Leg 6': '89',
    'Destination Code Leg 6': '90',
    'Date Leg 6': '91',
    'Cabin Class Leg 6': '92',
  };

  const properties = getCommonProperties(componentContext, now);
  const result = {};
  Object.keys(dimensionsMap).forEach((key) => {
    const dimension = dimensionsMap[key];
    if (properties[key]) {
      result[dimension] = properties[key];
    }
  });

  result['57'] = [
    properties.Adults,
    properties.Children,
    properties.Infants,
  ].reduce((total, num) => total + num);
  result['58'] = analyticsPageName;

  for (let i = 2; i <= 6; ++i) {
    if (result[dimensionsMap[`Date Leg ${i}`]]) {
      result[dimensionsMap[`Cabin Class Leg ${i}`]] =
        componentContext.cabinclass;
    }
  }
  return result;
};

const setSkippyDimensions = (componentContext, skippyConfig, trackerPrefix) => {
  let payloadSize = 0;

  if (skippyConfig) {
    // [outbound, inbound, date, carrier ]
    const legToDimensionMappings = {
      0: [45, 46, 47, 50],
      1: [93, 94, 95, 96],
      2: [97, 98, 99, 100],
      3: [101, 102, 103, 104],
      4: [105, 106, 107, 108],
      5: [109, 110, 111, 112],
    };

    const legs = skippyConfig.itineraryLegs;
    if (legs) {
      for (let i = 0, { length } = legs; i < length; i++) {
        const leg = legs[i];
        const mappings = legToDimensionMappings[i];
        payloadSize += setDimension(mappings[1], leg.Inbound, trackerPrefix);
        payloadSize += setDimension(mappings[0], leg.Outbound, trackerPrefix);
        payloadSize += setDimension(mappings[2], leg.Date, trackerPrefix);
        payloadSize += setDimension(mappings[3], leg.Carrier, trackerPrefix);
      }
    }

    payloadSize += setDimension(44, skippyConfig.redirectId, trackerPrefix);
    payloadSize += setDimension(
      48,
      componentContext.inboundDate,
      trackerPrefix,
    );
    payloadSize += setDimension(49, skippyConfig.websiteId, trackerPrefix);
    payloadSize += setDimension(51, 'transport', trackerPrefix);
  }

  return payloadSize;
};

const pageLoadedGaTracking = (
  gaPageView,
  trackerPrefix,
  trackingOptions,
  componentContext,
  pageSpecificGaDimensions,
  ancillary,
  analyticsPageName,
) => {
  const { location } = GLOBAL.getDocument();
  const { ga } = GLOBAL.getWindow();
  const viewport = getViewportSize();
  const viewportDimensions = `${viewport.width}x${viewport.height}`;

  if (ga) {
    ga(`${trackerPrefix}require`, 'linker');
    ga(`${trackerPrefix}require`, 'displayfeatures');
    ga(`${trackerPrefix}linker:autoLink`, trackingOptions.GaSkyscannerDomains);
  }

  let payloadSize = 0;

  payloadSize += setDimension(1, trackingOptions.WebNode, trackerPrefix);
  payloadSize += setDimension(2, 'POD_UNKNOWN', trackerPrefix);
  payloadSize += setDimension(
    3,
    trackingOptions.SvnBuildRevision,
    trackerPrefix,
  );
  payloadSize += setDimension(
    5,
    trackingOptions.CurrentUserLanguage,
    trackerPrefix,
  );
  payloadSize += setDimension(10, viewportDimensions, trackerPrefix);
  payloadSize += setDimension(62, componentContext.cabinClass, trackerPrefix);
  payloadSize += setDimension(143, componentContext.market, trackerPrefix);
  if (trackingOptions.userId) {
    payloadSize += setDimension(145, trackingOptions.userId, trackerPrefix);
  }
  payloadSize += setDimension(
    146,
    trackingOptions.preferencesId,
    trackerPrefix,
  );
  payloadSize += setDimension(
    130,
    trackingOptions.visibilityState,
    trackerPrefix,
  );
  payloadSize += setDimension(148, componentContext.deviceModel, trackerPrefix);
  payloadSize += setDimension(149, location.pathname, trackerPrefix);

  payloadSize += setDimension(
    153,
    trackingOptions['Gdpr Information'],
    trackerPrefix,
  );
  payloadSize += setDimension(
    154,
    trackingOptions['Gdpr Adverts'],
    trackerPrefix,
  );
  payloadSize += setDimension(
    155,
    trackingOptions['Gdpr Version'],
    trackerPrefix,
  );
  payloadSize += setDimension(
    156,
    trackingOptions['Gdpr Active'],
    trackerPrefix,
  );
  payloadSize += setDimension(
    157,
    trackingOptions['Gdpr Initialised'],
    trackerPrefix,
  );
  payloadSize += setDimension(158, trackingOptions.pageType, trackerPrefix);
  payloadSize += setDimension(159, trackingOptions.userLocale, trackerPrefix);
  payloadSize += setDimension(160, trackingOptions.userCurrency, trackerPrefix);
  payloadSize += setDimension(
    161,
    trackingOptions.userGeoMarket,
    trackerPrefix,
  );

  payloadSize += setSkippyDimensions(
    componentContext,
    trackingOptions.config.skippy,
    trackerPrefix,
  );

  if (
    trackingOptions.ShouldLogGaFeatureSetInDimension === true &&
    analyticsPageName !== 'flightskippy'
  ) {
    payloadSize += setDimension(
      19,
      trackingOptions.featureTestString ||
        getGaFeatureTestString(trackingOptions.config),
      trackerPrefix,
    );
  }

  if (trackingOptions.VarnishABGroup !== 'UNKNOWN') {
    payloadSize += setDimension(
      18,
      trackingOptions.VarnishABGroup,
      trackerPrefix,
    );
  }

  if (pageSpecificGaDimensions) {
    Object.keys(pageSpecificGaDimensions).forEach((key) => {
      payloadSize += setDimension(
        key,
        pageSpecificGaDimensions[key],
        trackerPrefix,
      );
    });
  }

  if (componentContext.priceAlertId) {
    payloadSize += setDimension(
      129,
      componentContext.priceAlertId,
      trackerPrefix,
    );
  }

  if (ancillary === 'airli') {
    const multiDestProperties = gaMultiDestProperties(
      componentContext,
      new Date(),
      analyticsPageName,
    );
    Object.keys(multiDestProperties).forEach((dimension) => {
      payloadSize += setDimension(
        dimension,
        multiDestProperties[dimension],
        trackerPrefix,
      );
    });
  }

  trackPageView(gaPageView, trackerPrefix, trackingOptions.allowedQueryParams);

  return payloadSize;
};

const initGaPageLoadedTracking = (
  trackingOptions,
  componentContext,
  pageSpecificGaDimensions,
  ancillary,
  analyticsPageName,
  uaPageView = '/',
) => {
  const { ga } = GLOBAL.getWindow();

  if (ga) {
    ga('create', 'UA-246109-1', 'auto', {
      allowLinker: true,
      siteSpeedSampleRate: 0,
    });
  }

  const payloadSizes = {};

  if (uaPageView) {
    payloadSizes.default = pageLoadedGaTracking(
      uaPageView,
      '',
      trackingOptions,
      componentContext,
      pageSpecificGaDimensions,
      ancillary,
      analyticsPageName,
    );
  }
  initGaListeners(
    uaPageView,
    analyticsPageName,
    trackingOptions.allowedQueryParams,
  );

  return payloadSizes;
};

export {
  pageLoadedGaTracking,
  gaMultiDestProperties,
  initGaPageLoadedTracking,
};
