const redactQueryString = (queryString, allowedQueryParams) => {
  const allowedParams = queryString.split('&').reduce((result, param) => {
    const paramKey = param.split('=')[0];
    if (allowedQueryParams.indexOf(paramKey) > -1) {
      return [...result, param];
    }
    return [...result, `${paramKey}=REDACTED`];
  }, []);
  return allowedParams.join('&');
};

const redactUrl = (url, { allowedQueryParams }) => {
  const hasQueryParams = url.indexOf('?') > -1;
  if (hasQueryParams && allowedQueryParams) {
    try {
      const [baseUrl, queryString] = url.split(/\?(.*)/, 2);
      const redactedQueryString = redactQueryString(
        queryString,
        allowedQueryParams,
      );
      return `${baseUrl}?${redactedQueryString}`;
    } catch (error) {
      return url;
    }
  }
  return url;
};

export default redactUrl;
