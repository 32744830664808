import { SingleValueCookie } from 'saddlebag-cookie';

import setPreferences from './setPreferences';

const getPreferences = () => {
  setPreferences();
  return Promise.resolve(new SingleValueCookie('preferences').getValue());
};

const getTrackIdentifier = () =>
  Promise.all([
    Promise.resolve(new SingleValueCookie('sstrack').getValue()),
    Promise.resolve(new SingleValueCookie('traveller_context').getValue()),
    getPreferences(),
  ]).then(([ga, utid, preferences]) => ({
    ga,
    utid,
    preferences,
  }));

export default getTrackIdentifier;
